hr {
    color: white !important;
    height: 2px !important;
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer-col {
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
}

.payment-method-img {
    width: 30%;
    max-width: 50px;
    margin: 1%;
    border-radius: 100%;
}

.nav-link {
    min-width: fit-content;
}

.footer-col {
    width: 100%;
}

.mobile-apps-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    a {
        width: 100%;
    }

    img {
        width: 30%;
        margin: 0 1%;
    }
}

@media screen
    and (max-width: 1250px) {
        .mobile-apps-link {
            img {
                width: 50%;
            }
        } 
}

@media screen
    and (max-width: 600px) {
        .mobile-apps-link {
            img {
                width: 50%;
                max-width: 200px;
            }
        } 
}