.menu-item {
    flex: 0 0 31%;
    margin: 1%;
    align-items: center;
    color: white;
    .basket-item-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-self: center;
        margin-bottom: 5%;
        .item-price {
            font-size: 4vh;
            margin-right: 5%;
        }
    }
}

.card-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.portion-size-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2%;

    .portion-size-option {
        border: 1px solid orangered;
        width: 40%;
        border-radius: 5%;
    }

    .selected-portion-size-option {
        background-color: orangered;
    }
}

.btn {
    border-color: rgba(255, 69, 0, 1) !important;
    color: white !important;
}

.btn-primary, .to-basket-button {
    background: rgba(255, 69, 0, 1) !important;
}

.to-basket-button {
    display: flex;
    align-items: center;
}

.info-container {
    color: lightgrey;
    text-align: left;
}

.discount-label {
    position: absolute;
    width: 20%;
    left: 80%;
    background-color: black;
    border-bottom: 1px solid orangered;
    border-left: 1px solid orangered;
    border-bottom-left-radius: 30%;
}

.no-discount-price {
    text-align: left;
    width: 100%;
    text-decoration: line-through;
    font-size: large;
}

@media (max-width: 1400px) {
    .menu-item {
        flex: 0 0 47%;
    }
}

@media (max-width: 715px) {
    .menu-item {
        flex: 0 0 96%;
    }
}