.types-list-container {
    width: 100%;
    min-width: 17vw;
    white-space: nowrap;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-right: 1%;
    span {
        margin-right: 2%;
    }
}