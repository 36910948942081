.carousel-item {
    img {
        max-height: 90vh;
        max-width: 100%;
        width: auto;
        margin: 0 auto;
    }

    @media screen 
    and (max-width: 1200px) {
        img {
            height: 30vh;
        }
    }
}