.old-image-form {
    margin-top: 1%;
    margin-bottom: 1%;
    border: 1px solid white;
    border-radius: 10px;
    padding: 2%;
}

.old-image-form__delete-button {
    margin-top: 2%;
}