.small-navbar-container {
    width: 100%;
    height: 8vh;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid white;

    h2 {
        text-decoration: underline;
        color: white;
        cursor: pointer;
        font-size: larger;
    }

}