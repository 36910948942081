@use '../_base' as *;

.countdown-container {
    width: 100%;
    background-color: $dark-grey-bg;
    min-height: 10vh;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    img {
        margin: 1%;
        width: 80%;
    }

    h3, h2 {
        margin: 1% 5%;
    }

    h3 {
        color: orangered;
    }

}

img {
    width: 100%;
}

.to-top-link {
    text-decoration: none;
    color: orangered;
}

.to-top-link:hover {
    color: orange;
}