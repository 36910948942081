html {
  background: black;
}

#root {
  background: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.App {
  text-align: center;
  width: 90%;
  margin-top: 2%;
}

.apps-promoter-scope .AppsPromoter .app-promoter-container {
  background-color: #222;
  border-top: 1px solid #999;
}
.apps-promoter-scope .AppsPromoter .app-promoter-content-header-title {
  color: #999;
}

@import "custom"