@use '../_base' as *;

.delivery-info-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    div {
        background-color: $dark-grey-bg;
        width: 80%;
        flex: 0 0 80%;
        margin: 1%;
        padding: 0 .5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        div {
            width: 100%;
            flex: 0 0 100%;
        }
    }
    h1 {
        width: 100%;
        flex: 0 0 100%;
    }
}

.delivery-regions-list-container {
    width: 80%;
    min-width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}