.about-us-controls {
    button {
        margin: 5px;
    }
}

.new-paragraph-form {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,.6);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease-in-out;

    textarea {
        width: 80vw;
        height: 30vh;
    }

    button {
        margin-top: 2%;
    }

    h1 {
        text-decoration: none;
        color: white;
    }
}

.new-paragraph-form.active-popup {
    opacity: 1;
    pointer-events: all;
    transition: 0.5s ease-in-out;
}
