.menu-items-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.menu-items-list-container {
    display: flex;
    flex-direction: column;
    width: 80%;

    h2, h3, input {
        margin-top: 1%;
    }

    input {
        margin-bottom: 1%;
    }
}

.pizza-discount-container {
    h4 {
        font-size: x-large;
        color: orangered;
    }

    p {
        font-size: smaller !important;
    }
}

@media (max-width: 1040px) {
    .menu-items-list-container {
        width: 100%;
    }
}
