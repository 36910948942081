.address-suggestion {
    background-color: white;
    color: black;
}

.address-suggestion:hover {
    background-color: lightgray;
}

label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-label {
    display: block;
}

.address-input {
    width: 90%;
    height: 10vh;
    word-wrap: normal;
}