.menu-types-list {
    width: 17%;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    height: min-content;
    min-width: min-content;
    margin-right: 2%;
    h2 {
        text-decoration: underline;
        cursor: pointer;
    }
    img {
        height: 24px;
        width: 24px;
        margin-right: 5px;
    }
    .list-group-item {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        cursor: pointer;
        height: 8vh;
        font-size: 2.5vh;
    }
}

.menu-types-list-horizontal {
    width: 100%;
    .menu-types-list-header {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        width: 100%;
        border-bottom: 1px solid white;
        .more-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1%;
            margin-left: 1%;
            cursor: pointer;
        }
        h2 {
            align-self: center;
            text-decoration: none;
        }
    }
    .menu-types-list-header-expanded {
        justify-content: center;
    }
    .menu-types-container-wide {
        width: 100%;
        flex-direction: column;
        .list-group-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            height: 8vh;
            font-size: 2.5vh;
        }
    }
}