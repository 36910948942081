.popup-logo-container{
    max-height: 100vmin;
    max-width: 100vmin;
    position: fixed;
    z-index: 4;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    background: rgba(0, 0, 0, .8);
    // animation: drop-and-fly-away 3.5s ease .5s 1 forwards;
    justify-self: center;
    align-self: center;
    img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
    }
}

@keyframes drop-and-fly-away {
    0% {
        opacity: 0;
        width: 100vw;
        height: 100vh;
    }

    15% {
        opacity: 1;
        width: 100vw;
        height: 100vh;
    }

    85% {
        top: 0;
        width: 100vw;
        height: 100vh;
    }

    100% {
        top: 50%;
        width: 0;
        height: 0;
        display: none;
    }
}

#app-router {
    width: 100%;
}

#landing-container {
    width: 100%;
}